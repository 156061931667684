import { format } from "date-fns"
import React, { PropsWithChildren } from "react"

export interface DayEntry {
  soulSummary: string
  emotionalLevel: number
  emotionalSummary: string

  physicalLevel: number
  physicalSummary: string

  mentalLevel: number
  mentalSummary: string

  date: string

  setSoulSummary: (s: string) => void

  setEmotionalLevel: (l: number) => void
  setEmotionalSummary: (s: string) => void

  setPhysicalLevel: (l: number) => void
  setPhysicalSummary: (s: string) => void

  setMentalLevel: (l: number) => void
  setMentalSummary: (s: string) => void
}

const DataContext = React.createContext<DayEntry>({
  soulSummary: "",
  emotionalLevel: 0,
  emotionalSummary: "",
  physicalLevel: 0,
  physicalSummary: "",
  mentalLevel: 0,
  mentalSummary: "",
  date: format(new Date(), "yyyy-MM-dd"),

  setSoulSummary: (s: string) => {},

  setEmotionalLevel: (l: number) => {},
  setEmotionalSummary: (s: string) => {},

  setPhysicalLevel: (l: number) => {},
  setPhysicalSummary: (s: string) => {},

  setMentalLevel: (l: number) => {},
  setMentalSummary: (s: string) => {},
})

export const useTodayData = () => React.useContext(DataContext)

export const DataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [soulSummary, setSoulSummary] = React.useState<string>("")
  //   const [extraShare, setExtraShare] = React.useState<string>("")

  const [physicalLevel, setPhysicalLevel] = React.useState<number>(0)
  const [physicalSummary, setPhysicalSummary] = React.useState<string>("")

  const [mentalLevel, setMentalLevel] = React.useState<number>(0)
  const [mentalSummary, setMentalSummary] = React.useState<string>("")

  const [emotionalLevel, setEmotionalLevel] = React.useState<number>(0)
  const [emotionalSummary, setEmotionalSummary] = React.useState<string>("")

  return (
    <DataContext.Provider
      value={{
        emotionalLevel,
        mentalLevel,
        physicalLevel,
        emotionalSummary,
        mentalSummary,
        physicalSummary,
        soulSummary,
        setSoulSummary,
        setPhysicalLevel,
        setPhysicalSummary,
        setMentalLevel,
        setMentalSummary,
        setEmotionalLevel,
        setEmotionalSummary,
        date: React.useMemo(() => format(new Date(), "yyyy-MM-dd"), []),
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
