import { usePWA } from "."
import { Button } from "./PWAcomponents"

export const UpdateAppButton = () => {
  const { updateAvailable } = usePWA()

  if (updateAvailable)
    return <Button onClick={() => window.location.reload()}>Update</Button>
  return null
}
