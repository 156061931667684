import { TextArea } from "Components/Input"
import { TextPrimary, TextSecondary } from "Components/Typography"
import React from "react"
import styled from "styled-components/macro"
import { myTheme } from "theme"

interface Props {
  title: string
  value: number
  setValue: (num: number) => void
  message: string
  setMessage: (msg: string) => void
  color: keyof typeof myTheme.colors
}

export const NumberGague: React.FC<Props> = ({
  value,
  setValue,
  message,
  setMessage,
  title,
  color,
}) => {
  return (
    <div className="d-flex w-100 justify-content-between flex-column align-items-center my-0">
      <TextSecondary weight={800} color={color} className="my-1">
        {title}
      </TextSecondary>
      <div className="d-flex w-100 justify-content-between mb-2">
        {[...Array(10).keys()].map((i) => {
          return (
            <div
              onClick={() => setValue(i)}
              className="d-flex  flex-column align-items-center "
            >
              <TextPrimary
                size="md"
                weight={800}
                color={value === i ? color : "transparent"}
              >
                {i + 1}
              </TextPrimary>
              <RadioButton key={i} filled={value === i} color={color} />
            </div>
          )
        })}
      </div>
      <TextArea
        value={message}
        rows={1}
        fullWidth
        color={color}
        setValue={setMessage}
        valueMaxLength={150}
        iconCode="f185"
        placeholder={`Extra notes on your ${title} state`}
        error={undefined}
        onFocus={() => {}}
      />
    </div>
  )
}

export const RadioButton = styled.div<{
  filled: boolean
  color: keyof typeof myTheme.colors
}>`
  width: 20px;
  height: 20px;
  margin: 0 0.25rem;
  padding: 0.25rem;
  border-radius: 50%;
  transition: all ease-in-out 0.3s;
  border: ${(props) =>
    `1px solid ${
      props.filled
        ? props.theme.colors[props.color]
        : props.theme.colors.transparent
    }`};
  background: ${(props) =>
    props.filled
      ? props.theme.colors[props.color]
      : props.theme.colors.background};
`
