import { ChartDatum, OverviewChart } from "Components/Chart/Chart"
import { ContainerDiv } from "Components/Layout/Containers"
import { TextPrimary } from "Components/Typography"
import { DayEntry } from "Providers/DataProvider"
import React from "react"

/**
 * @param s 2023-02-20
 * @returns <Date> Mon Feb 20 2023 00:00:00 GMT-0700 (Mountain Standard Time
 */
export const stringToDate = (s: string) => {
  // const incomingDate = d
  try {
    const today = new Date()
    // zero out the time
    today.setHours(0, 0, 0, 0)
    const year = Number(s.split("-")[0])
    const month = Number(s.split("-")[1])
    const day = Number(s.split("")[2])
    today.setFullYear(year)
    today.setMonth(month - 1)
    today.setDate(day)
    return today
  } catch (err) {
    // TODO: Temporarily removing these since they spam since somewhere in our DayZoomIn component we have a date bug that leads to a ton of bad dates coming in here
    console.warn("Bad date provided")
    console.warn(err)
    console.warn(err)
    return new Date()
  }
}

export const Overview: React.FC = () => {
  const existingEntries = JSON.parse(
    localStorage.getItem("soul-app-data") || "{}"
  ) as { [index: string]: DayEntry }

  const dateStrings = Object.keys(existingEntries)
  console.log({ dateStrings })
  const labels = Object.keys(existingEntries).map((dateString) => dateString)

  console.log({ labels })

  const physicalValues = Object.values(existingEntries).map(
    (value) => value.physicalLevel
  )
  const mentalValues = Object.values(existingEntries).map(
    (value) => value.mentalLevel
  )
  const emotionalValues = Object.values(existingEntries).map(
    (value) => value.emotionalLevel
  )

  const chartData: ChartDatum[] = [
    {
      label: "Physical",
      data: physicalValues,
      color: "blue",
    },
    {
      label: "Emotional",
      data: emotionalValues,
      color: "red",
    },
    {
      label: "Mental",
      data: mentalValues,
      color: "green",
    },
  ]

  return (
    <ContainerDiv>
      <TextPrimary
        className="align-self-center"
        color="transparent"
        size="lg"
        weight={200}
      >
        Overview
      </TextPrimary>
      <OverviewChart labels={labels as any} allData={chartData} />
    </ContainerDiv>
  )
}
