import { fireConfetti } from "Components/Confetti"
import { Content, Footer, Header, Main } from "Components/Layout/Containers"
import { Icon, StyledHr, StyledVr, TextPrimary } from "Components/Typography"
//import { sub } from "date-fns"
import format from "date-fns/format"
import { Overview } from "Pages/Overview"
import { Today } from "Pages/Today"
import { DayEntry, useTodayData } from "Providers/DataProvider"
import { UpdateAppButton } from "Providers/PWAProvider/UpdateAppButton"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"

function App() {
  const go = useNavigate()
  const todayFormatted = format(new Date(), "MMM dd, yyyy")
  const { pathname } = useLocation()
  const { date, ...rest } = useTodayData()

  //const date = format(sub(new Date(), { days: 6 }), "yyyy-MM-dd")

  return (
    <Main>
      <UpdateAppButton />
      <Header>
        <TextPrimary size="lg" color="primary" className="mr-1">
          SoulApp
        </TextPrimary>
        <img src="/logo.png" style={{ maxHeight: "24px" }} alt="" />
        <StyledHr weight={1} color="primary" className="mx-3" />
        <TextPrimary
          style={{ minWidth: "fit-content" }}
          size="md"
          color="transparent"
        >
          {todayFormatted}
        </TextPrimary>
      </Header>
      <Content>
        <Routes>
          <Route path="/" element={<Today />} />

          <Route path="/overview" element={<Overview />} />
        </Routes>
      </Content>
      <Footer>
        <div
          onClick={() => go(pathname === "/" ? "/overview" : "/")}
          style={{ flex: 1 }}
          className="d-flex align-items-start justify-content-center h-100"
        >
          <Icon
            color="background"
            size="lg"
            className={
              pathname === "/" ? "fa-solid fa-calendar-days" : "fas fa-pen"
            }
          />
        </div>
        {pathname === "/" ? (
          <>
            <StyledVr color="background" size="xl" className="mx-1" />
            <TextPrimary
              style={{ flex: 1, textAlign: "center" }}
              weight={800}
              color="background"
              onClick={() => {
                const existingEntries = JSON.parse(
                  localStorage.getItem("soul-app-data") || "{}"
                ) as { [index: string]: DayEntry }

                existingEntries[date] = { date, ...rest }

                localStorage.setItem(
                  "soul-app-data",
                  JSON.stringify(existingEntries)
                )
                fireConfetti({ duration: 0.5 })
              }}
              size="lg"
            >
              Save
            </TextPrimary>
          </>
        ) : null}
      </Footer>
    </Main>
  )
}

export default App

/**
 * 
# Each Day Will Be
data model:
{
    soulOverview: string
    
    physical: enum (0-9)
    physicalNotes: string

    mental: enum (0-9)
    mentalNotes: string

    emotional: enum (0-9)
    emotionalNote:string
    
    extraShare: string

    date: string (yyyy-mm-dd)
}[]


routes:
    /today (the input screen)
    /dashboard (calendar + graph)

    clicking on a day in the calendar animates in a page with the input screen in read only mode


components:
    InputScreen (input mode vs. readonly mode)
        Add save button to the bottom - until user clicks on this, no entry has been added

        - entire page should allow scrolling
        - in readonly mode:
        remove radio button inputs, show large number
        change textarea boxes to be collapsible titles with text within
        show a close button that takes user back to calendar page
    
        Label:
            mvp: text
            fancy: cool fonts and glowing as input increases
        LevelInput:
            mvp: 10 simple radio buttons
            mlp: 10 custom radio buttons with colors
            fancy: animated slider that moves across to the 10 notches
        TextAreaBoxes:
            max length counter
        BottomFooterButtons

    Calendar
        omit data points in calendar (on click does nothing) if day is missed
        CalendarDaySquare:
            onClick = popup input screen in read only mode
                      (if clicking on future date, do nothing)
            onTouchStart = show hover preview, update said preview as you move touched-thumb across
            contents = overall score for that day ()
    Graph:
        For each metric, graph the history of that metric over the last week
        omit data points in the graph if day is missed





 */
