export const HEADER_SIZE = 24
export const FOOTER_SIZE = 72

export const TOP_CONTAINER_PADDING_FOR_NOTCH = 64
export const TOP_CONTAINER_PADDING_FOR_FOOTER = 24

export const CONTENT_PADDING_TOP = 16
export const CONTENT_PADDING_HORIZONTAL = 24

export const TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT =
  HEADER_SIZE +
  FOOTER_SIZE +
  TOP_CONTAINER_PADDING_FOR_NOTCH +
  CONTENT_PADDING_TOP

export const getGridTemplateRows = () => {
  return `${HEADER_SIZE}px 1fr ${FOOTER_SIZE}px`
}

export const getGridTemplateAreas = () => {
  return `
      "header"
      "content"
      "footer"
      `
}

export const getPaddingForContent = () => {
  return `${CONTENT_PADDING_TOP}px ${CONTENT_PADDING_HORIZONTAL}px 0`
}
