import styled from "styled-components/macro"
import { myTheme } from "theme"

interface MoneyInputContainerProps {
  label?: string
  fontSize?: string
  color?: keyof typeof myTheme.colors
  icon: string
  inputAtLimit?: boolean
  error?: boolean
}
export const InputContainer = styled.div<MoneyInputContainerProps>`
  position: relative;
  margin-bottom: 1rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.transparent};
  &:focus-within {
    color: ${(props) =>
      props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.primary};
  }
  &:active {
    color: ${(props) =>
      props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.primary};
  }
  input {
    &:focus::placeholder {
      color: ${(props) =>
        props.error
          ? props.theme.colors.error
          : props.theme.colors.transparent};
    }

    width: 100%;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.transparent};
    transition: all ease-in-out 0.3s;
    border-radius: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:focus {
      color: ${(props) =>
        props.color
          ? props.theme.colors[props.color]
          : props.theme.colors.primary};
      border-color: ${(props) =>
        props.color
          ? props.theme.colors[props.color]
          : props.theme.colors.primary};
    }
    font-family: ${(props) => props.theme.fonts.family.secondary};
    font-size: ${(props) => props.theme.fonts.sizes.md};
    color: ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.transparent};
    :disabled {
      color: ${(props) => props.theme.colors.transparent};
    }
    ::placeholder {
      color: ${(props) =>
        props.error
          ? props.theme.colors.error
          : props.theme.colors.transparent};
    }
    &:focus::after {
      color: ${(props) =>
        props.color
          ? props.theme.colors[props.color]
          : props.theme.colors.primary};
    }
    &:focus::before {
      color: ${(props) =>
        props.color
          ? props.theme.colors[props.color]
          : props.theme.colors.primary};
    }
  }

  textarea {
    &:focus::placeholder {
      color: transparent;
    }
    border-style: solid;
    width: 100%;
    border-width: 1px;
    padding: 0.625rem;
    line-height: ${(props) => props.theme.fonts.sizes.lg};
    border-color: ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.transparent};
    transition: all ease-in-out 0.3s;
    border-radius: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:focus {
      outline: none;
      color: ${(props) =>
        props.color
          ? props.theme.colors[props.color]
          : props.theme.colors.primary};
      border-color: ${(props) =>
        props.color
          ? props.theme.colors[props.color]
          : props.theme.colors.primary};
    }
    font-family: ${(props) => props.theme.fonts.family.secondary};
    font-size: ${(props) => props.theme.fonts.sizes.xs};
    color: ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.transparent};
    :disabled {
      color: ${(props) => props.theme.colors.transparent};
    }
    ::placeholder {
      opacity: 0.3;
      color: ${(props) =>
        props.error
          ? props.theme.colors.error
          : props.theme.colors.transparent};
    }
    &:focus::after {
      color: ${(props) =>
        props.color
          ? props.theme.colors[props.color]
          : props.theme.colors.primary};
    }
    &:focus::before {
      color: ${(props) =>
        props.color
          ? props.theme.colors[props.color]
          : props.theme.colors.primary};
    }
  }

  span {
    position: absolute;
    bottom: -1.75em;
    right: 0;
    color: ${(props) =>
      props.inputAtLimit
        ? props.theme.colors.error
        : props.error
        ? props.theme.colors.error
        : props.theme.colors.transparent};
  }

  /* Bring this back to keep an icon on the left of the field */
  /* ::before {
    position: absolute;
    color: ${(props) => (props.error ? props.theme.colors.error : "inherit")};
    content: "${({ icon }) => `\\${icon}`}";
    font-family: "Font Awesome 5 Pro";
    font-size: ${(props) => props.theme.fonts.sizes["sm"]};
    transition: all ease-in-out 0.3s;
    display: block;
    font-weight: 700;
    left: -1.5em;
    bottom: 0;
  } */
  ::after {
    content: "${({ label }) => `${label}`}";
    position: absolute;
    color: ${(props) => (props.error ? props.theme.colors.error : "inherit")};
    font-family: ${(props) => props.theme.fonts.family.primary};
    transition: all ease-in-out 0.3s;
    font-size: ${(props) => props.theme.fonts.sizes["sm"]};
    display: block;
    font-weight: 700;
    bottom: -1.75em;
    left: 0;
  }
`
