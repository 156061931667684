import styled from "styled-components/macro"
import { myTheme } from "../theme"

type TightnessType = "spaced" | "tight" | "supertight" | "superspaced"

const tightnessToSpacingValue = (tightness: TightnessType | undefined) => {
  switch (tightness) {
    case "superspaced":
      return "2px"
    case "spaced":
      return "1px"
    case "tight":
      return "-.5px"
    case "supertight":
      return "-1px"
    default:
      return "0px"
  }
}

interface TextProps {
  size?: keyof (typeof myTheme)["fonts"]["sizes"]
  textAlign?: "left" | "center" | "right"
  alignSelf?: "flex-start" | "center" | "flex-end"
  fontFamily?: keyof (typeof myTheme)["fonts"]["family"]
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  tightness?: TightnessType
  color?: keyof (typeof myTheme)["colors"]
  inactive?: boolean
}

export const TextSecondary = styled.p<TextProps>`
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || "sm"]};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 300]};
  color: ${(props) => props.theme.colors[props?.color || "text"]};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  transition: color ease-in-out 0.3s;
  margin: 0;

  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
`

export const TextSpan = styled.span<TextProps>`
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};
  font-size: ${(props) =>
    props.size ? props.theme.fonts.sizes[props.size] : "inherit"};
  font-weight: ${(props) =>
    props.weight ? props.theme.fonts.weights[props.weight] : "inherit"};
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : "inherit"};
  font-family: ${(props) =>
    props.theme.fonts.family[props?.fontFamily || "secondary"]};
  transition: all ease-in-out 0.3s;
  margin: 0;
  display: inline-flex;
  align-items: center;
  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
`

interface StyledHrProps {
  weight?: 0.5 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  color?: keyof (typeof myTheme)["colors"]
  borderStyle?: "solid" | "dashed" | "dotted"
}
export const StyledHr = styled.hr<StyledHrProps>`
  border-color: ${(props) => props.theme.colors[props?.color || "text"]};
  border-width: 0;
  border-bottom-width: ${(props) => `${props.weight || 2}px`};
  border-style: ${(props) => props?.borderStyle || "solid"};
  background: transparent;
  transition: all ease-in-out 0.3s;

  box-shadow: ${(props) =>
    `0 0 15px 0 ${props.theme.colors[props?.color || "primary"]}`};

  width: 100%;
  margin: 0;
`

interface StyledVrProps {
  weight?: 0.5 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  size?: keyof (typeof myTheme)["fonts"]["sizes"]
  color?: keyof (typeof myTheme)["colors"]
  borderStyle?: "solid" | "dashed" | "dotted"
}
export const StyledVr = styled.div<StyledVrProps>`
  background: ${(props) => props.theme.colors[props?.color || "transparent"]};
  height: ${(props) => props.theme.fonts.sizes[props?.size || "lg"]};
  width: ${(props) => `${props.weight || 2}px`};

  transition: all ease-in-out 0.3s;
  margin: 0;
`

export const TextPrimary = styled.p<TextProps>`
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || "sm"]};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || "300"]};
  font-family: ${(props) =>
    props.theme.fonts.family[props?.fontFamily || "primary"]};
  color: ${(props) => props.theme.colors[props?.color || "text"]};
  margin: 0;
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};

  transition: all ease-in-out 0.3s;
`

export const Icon = styled.i<TextProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || "sm"]};
  transition: all ease-in-out 0.3s;
  opacity: ${(props) => (props.inactive ? 0.3 : 1)};
  color: ${(props) => props.theme.colors[props?.color || "text"]};
  --fa-secondary-color: ${(props) => props.theme.colors.primary};
`
