import {
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
} from "chart.js"
import "chartjs-adapter-date-fns"
import { enUS } from "date-fns/locale"
import React from "react"
import { Scatter } from "react-chartjs-2"
import { myTheme } from "theme"
import { ChartContainer } from "./components"

// import rocketimagesvg from './rocket-launch.svg'

// const RocketImage = new Image()

// RocketImage.src = rocketimagesvg
// RocketImage.width = 15
// RocketImage.height = 15

Chart.register(TimeScale)
Chart.register(TimeSeriesScale)
Chart.register(LinearScale)
Chart.register(PointElement)
Chart.register(LineController)
Chart.register(LineElement)
Chart.register(Legend)
Chart.register(Filler)

export interface ChartDatum {
  label: string
  color: keyof typeof myTheme.colors
  data: number[]
}

interface Props {
  labels: Date[]
  allData: ChartDatum[]
}

export const OverviewChart: React.FC<Props> = ({ labels, allData }) => {
  return (
    <ChartContainer
      style={{
        transition: "all ease-in-out .3s",
      }}
    >
      <Scatter
        redraw={false}
        options={{
          animations: {
            tension: {
              duration: 300,
              easing: "linear",
              from: 1,
              to: 0,
              loop: false,
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
          maintainAspectRatio: false,

          responsive: true,
          plugins: {
            legend: {
              labels: {
                color: "pink",
                font: {
                  family: "Rubik",
                },
              },
            },
          },

          scales: {
            x: {
              type: "time",

              adapters: {
                date: {
                  locale: enUS,
                },
              },

              time: {
                unit: "day",
                displayFormats: {
                  day: "MMM dd yy",
                },
              },

              ticks: {
                source: "data",
                autoSkip: true,
                autoSkipPadding: 7,
              },
              alignToPixels: true,

              grid: {
                display: true,
              },
            },
            y: {
              ticks: {
                // Include a dollar sign in the ticks
                includeBounds: true,
              },
              beginAtZero: false,
              grid: {
                display: true,
              },
            },
          },
        }}
        data={{
          labels,

          datasets: allData.map((datum) => {
            const { color, label, data } = datum
            console.log({ datum })
            return {
              stack: label,
              label: label,
              borderWidth: 2,

              // This is the radius of each data point
              pointRadius: 5,
              backgroundColor: myTheme.colors[color],
              pointBackgroundColor: myTheme.colors[color],
              pointBorderColor: myTheme.colors[color],

              type: "scatter",
              showLine: true,

              stepped: false,

              // tension: 0.25,

              segment: {
                borderColor: myTheme.colors[color],
              },

              data,
            }
          }),
        }}
      />
    </ChartContainer>
  )
}
