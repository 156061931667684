import { TextArea } from "Components/Input"
import { ContainerDiv } from "Components/Layout/Containers"
import { NumberGague } from "Components/NumberGauge"
import { TextPrimary } from "Components/Typography"
import { useTodayData } from "Providers/DataProvider"
import React from "react"

export const Today: React.FC = () => {
  const {
    emotionalLevel,
    emotionalSummary,
    setEmotionalLevel,
    setEmotionalSummary,
    setMentalLevel,
    mentalLevel,
    mentalSummary,
    physicalLevel,
    physicalSummary,
    setMentalSummary,
    setPhysicalLevel,
    setPhysicalSummary,
    setSoulSummary,
    soulSummary,
  } = useTodayData()
  return (
    <ContainerDiv>
      <TextPrimary
        className="align-self-center"
        color="transparent"
        size="lg"
        weight={200}
      >
        How is your soul today?
      </TextPrimary>
      <TextArea
        value={soulSummary}
        rows={2}
        setValue={setSoulSummary}
        valueMaxLength={350}
        iconCode="f185"
        placeholder="How are you feeling on the inside?"
        error={undefined}
        onFocus={() => {}}
      />
      <TextPrimary
        className="align-self-center"
        color="transparent"
        size="lg"
        weight={200}
      >
        Energy Check In
      </TextPrimary>
      <NumberGague
        color="blue"
        title="Physical"
        value={physicalLevel}
        message={physicalSummary}
        setMessage={setPhysicalSummary}
        setValue={setPhysicalLevel}
      />
      <NumberGague
        color="red"
        title="Emotional"
        value={emotionalLevel}
        setValue={setEmotionalLevel}
        message={emotionalSummary}
        setMessage={setEmotionalSummary}
      />
      <NumberGague
        color="green"
        title="Mental"
        value={mentalLevel}
        setValue={setMentalLevel}
        message={mentalSummary}
        setMessage={setMentalSummary}
      />
    </ContainerDiv>
  )
}
