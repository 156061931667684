// my-theme.ts
export const darkColors = {
  text: "white",
  secondary: "#F7F7F7",
  background: "#292929",
  blue: "#00A7E1",
  red: "#ED254E",
  green: "#C3E991",
  transparent: "#747474",
  error: "#FF4141",
  primary: "#FFAB00",
}

export const lightColors = {
  primary: "#6D4A00",
  background: "#BBBFBF",
  secondary: "#DFF8EB",
  transparent: "#7D7F7E80",
  blue: "#00A7E1",
  red: "#ED254E",
  green: "#C3E991",
  error: "#FF4141",
  text: "black",
}

export const colorPalletes = {
  dark: darkColors,
  light: lightColors,
}

export const myTheme = {
  borderRadius: "5px",

  paddings: {
    sm: ".25rem",
    md: ".5rem",
    lg: "1rem",
    xl: "2rem",
  },

  sizes: {
    banner: "3rem",
    large: "2rem",
    paragraph: "1rem",
  },
  colors: darkColors,
  fonts: {
    family: {
      primary: "Shadows Into Light, cursive",
      secondary: "Nunito, sans-serif",
    },
    weights: {
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900,
    },
    sizes: {
      xxxxs: ".575rem",
      xxxs: ".625rem",
      xxs: ".75rem",
      xs: ".875rem",
      sm: "1rem",
      smm: "1.125rem",
      md: "1.25rem",
      lg: "1.5rem",
      xl: "2rem",
      xxl: "2.5rem",
      xxxl: "4.5rem",
      xxxxl: "5.5rem",
    },
  },
}

export type ThemeType = typeof myTheme
