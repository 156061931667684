import styled from "styled-components/macro"
import {
  CONTENT_PADDING_HORIZONTAL,
  getGridTemplateAreas,
  getGridTemplateRows,
  getPaddingForContent,
  TOP_CONTAINER_PADDING_FOR_NOTCH,
  TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT,
} from "./helpers"

export const Main = styled.main`
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;

  padding-top: ${() => `${TOP_CONTAINER_PADDING_FOR_NOTCH}px`};
  background: ${(props) => props.theme.colors.background};
  transition: background 0.5s ease-in-out;
  color: ${(props) => props.theme.colors.text};

  display: grid;
  grid-template-rows: ${() => getGridTemplateRows()};
  grid-template-areas: ${() => getGridTemplateAreas()};
`

export const Content = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  grid-area: content;

  padding: ${() => getPaddingForContent()};

  color: ${(props) => props.theme.colors.text};
`

export const Footer = styled.footer`
  grid-area: footer;
  height: 100%;
  width: 100%;
  position: sticky;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  background: ${(props) => props.theme.colors.primary};
`

export const Header = styled.header`
  grid-area: header;

  padding-left: ${(props) => `${CONTENT_PADDING_HORIZONTAL}px`};
  padding-right: ${(props) => `${CONTENT_PADDING_HORIZONTAL}px`};
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.colors.background};
`

interface Props {
  cutout?: number
}
/**
 * A lot of this component does is take up the full space on the device as a fixed min/max height so that:
 * - it sits between the header and footer of the app based on their size
 * - allows for an optional cutout to be passed in so that it can exist on a page that has other content but still allows it to scroll
 * ex: the StreamPage also has a fixed, StreamPage-specific footer that we want glued to the bottom of the page and have the container above it take up the full remainder
 */
export const ScrollingFull = styled.section<Props>`
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  max-height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${
      props?.cutout || 0
    }px)`};
  min-height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${
      props?.cutout || 0
    }px)`};
  height: ${(props) =>
    `calc(100vh - (${TOTAL_VERTICAL_SPACE_OUTSIDE_CONTENT}px) - ${
      props?.cutout || 0
    }px)`};
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ContainerDiv = styled.div`
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  padding: 0rem;
  display: flex;
  flex-direction: column;
`

export const ContainerCutout = styled.div<{ size?: number }>`
  min-height: ${(props) => (props.size ? `${props.size}px` : "5rem")};
  max-height: ${(props) => (props.size ? `${props.size}px` : "5rem")};
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
`
