import React from "react"
import { myTheme } from "theme"
import { InputContainer } from "./components"

interface Props {
  value: string
  valueMaxLength: number
  setValue: (name: string) => void

  iconCode: string
  label?: string
  placeholder: string

  error?: boolean
  color?: keyof typeof myTheme.colors
  fullWidth?: boolean
}

export const TextInput: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  value,
  valueMaxLength,
  setValue,
  iconCode,
  label,
  fullWidth,
  placeholder,
  error,
  ...props
}) => {
  return (
    <InputContainer
      inputAtLimit={value.length === valueMaxLength}
      icon={iconCode}
      style={{ width: fullWidth ? "100%" : "" }}
      label={label}
      error={error}
    >
      <input
        {...props}
        value={value}
        maxLength={valueMaxLength}
        placeholder={placeholder}
        onChange={(e) => setValue(e.currentTarget.value)}
      ></input>
      <span>
        {value.length}/{valueMaxLength}
      </span>
    </InputContainer>
  )
}

export const TextArea: React.FC<
  Props & React.InputHTMLAttributes<HTMLTextAreaElement> & { rows?: number }
> = ({
  value,
  valueMaxLength,
  setValue,
  iconCode,
  fullWidth,
  label = "",
  color = "primary",
  placeholder,
  error,
  rows = 4,
  ...props
}) => {
  return (
    <InputContainer
      inputAtLimit={value.length === valueMaxLength}
      icon={iconCode}
      style={{ width: fullWidth ? "100%" : "" }}
      label={label}
      color={color}
      error={error}
    >
      <textarea
        {...props}
        rows={rows}
        value={value}
        maxLength={valueMaxLength}
        placeholder={placeholder}
        onChange={(e) => setValue(e.currentTarget.value)}
      />
      <span>
        {value.length}/{valueMaxLength}
      </span>
    </InputContainer>
  )
}
