import confetti from "canvas-confetti"
import { darkColors } from "theme"

interface ConfettiProps extends confetti.Options {
  /**
   * The number of seconds to be launching confetti
   */
  duration?: number
}

const isDesktop = window.matchMedia("(min-width: 1024px").matches
export const fireConfetti: (props: ConfettiProps) => void = ({
  duration = 1,
  spread = 360,
  particleCount = 10,
  ...rest
}) => {
  var end = Date.now() + duration * 1000

  ;(function frame() {
    confetti({
      particleCount,
      angle: 60,
      spread: spread,
      startVelocity: 30,
      zIndex: 5000,
      gravity: 0.3,
      origin: { x: isDesktop ? 0.4 : 0, y: 0 },
      colors: [darkColors.primary, darkColors.primary],
      ...rest,
    })
    confetti({
      particleCount,
      angle: 120,
      spread: spread,
      startVelocity: 30,
      zIndex: 5000,
      gravity: 0.3,
      origin: { x: 1, y: 0.5 },
      colors: [darkColors.primary, darkColors.primary],
      ...rest,
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}

export const blastConfetti: (props: ConfettiProps) => void = ({
  duration = 1,
  spread = 360,
  particleCount = 10,
  ...rest
}) => {
  var end = Date.now() + duration * 1000

  ;(function frame() {
    confetti({
      particleCount,
      angle: 60,
      spread: spread,
      startVelocity: 30,
      gravity: 0.3,
      origin: { x: isDesktop ? 0.4 : 0, y: 0 },
      colors: [darkColors.primary, darkColors.primary],
      ...rest,
    })
    confetti({
      particleCount,
      angle: 120,
      spread: spread,
      startVelocity: 30,
      gravity: 0.3,
      origin: { x: 1, y: 0.5 },
      colors: [darkColors.primary, darkColors.primary],
      ...rest,
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}

export const fireSlowConfetti: (props: ConfettiProps) => void = ({
  duration = 1,
  spread = 55,
  particleCount = 5,
  ...rest
}) => {
  var end = Date.now() + duration * 1000

  ;(function frame() {
    confetti({
      particleCount: 1,
      angle: -45,
      startVelocity: 15,
      gravity: 0.3,
      spread: spread,
      origin: { x: isDesktop ? 0.4 : 0, y: 0 },
      colors: ["#0a0444"],
      ...rest,
    })
    confetti({
      particleCount: 1,
      angle: -135,
      spread: spread,
      startVelocity: 15,
      gravity: 0.3,
      origin: { x: 1, y: 0 },
      colors: ["#0a0444"],
      ...rest,
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}
