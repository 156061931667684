import { DataProvider } from "Providers/DataProvider"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import App from "./App"
import "./index.css"
import { PWAProvider } from "./Providers/PWAProvider"
import reportWebVitals from "./reportWebVitals"
import "./reset.css"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { myTheme } from "./theme"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <PWAProvider>
        <ThemeProvider theme={myTheme}>
          <DataProvider>
            <App />
          </DataProvider>
        </ThemeProvider>
      </PWAProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
